import ApplicantsListPage from "./pages/applicant-list/ApplicantsListPage";
import ApplicantDetailPage from "./pages/applicant-details/ApplicantDetailPage";
import LoginPage from "./pages/login/LoginPage";
import CreateApplicantPage from "./pages/create-applicant/CreateApplicantPage";
import PlanningPage from "./pages/planning/PlanningPage";
import { NavigateFunction } from "react-router-dom";
import { SecurityLevel } from "./types/User";
import OfficeListPage from "./pages/office-list/OfficeListPage";
import JobCoachListPage from "./pages/job-coach-list/JobCoachListPage";
import JobCoachDetailsPage from "./pages/job-coach-details/JobCoachDetailsPage";
import OfficeDetailsPage from "./pages/office-details/OfficeDetailsPage";
import CompanyCreatePage from "./pages/company-create/CompanyCreatePage";
import ForgotPasswordPage from "./pages/forgot-password/ForgotPasswordPage";
import ChangePasswordPage from "./pages/change-password/ChangePasswordPage";
import ResetPasswordPage from "./pages/reset-password/ResetPasswordPage";
import SettingsPage from "./pages/settings/SettingsPage";
import AnalyticsPage from "./pages/analytics/AnalyticsPage";

export enum ViewMode {
  Compact,
  Wide,
}

interface MaijaRoute {
  path: string;
  nameKey: string;
  securityLevel: SecurityLevel;
  viewMode: ViewMode;
  component: React.ComponentType;
}

export const APPLICANT_LIST_ROUTE = {
  path: "/applicants",
  nameKey: "route.applicants_list",
  securityLevel: SecurityLevel.JobCoach,
  viewMode: ViewMode.Wide,
  component: ApplicantsListPage,
};

export const APPLICANT_DETAILS_ROUTE = {
  path: "/applicants/details/:id",
  nameKey: "route.applicants_details",
  securityLevel: SecurityLevel.JobCoach,
  viewMode: ViewMode.Wide,
  component: ApplicantDetailPage,
};

export const LOGIN_ROUTE = {
  path: "/login",
  nameKey: "route.login",
  securityLevel: SecurityLevel.None,
  viewMode: ViewMode.Compact,
  component: LoginPage,
};

export const CREATE_APPLICANT_ROUTE = {
  path: "/create",
  nameKey: "route.create",
  securityLevel: SecurityLevel.JobCoach,
  viewMode: ViewMode.Compact,
  component: CreateApplicantPage,
};

export const PLANNING_ROUTE = {
  path: "/planning",
  nameKey: "route.planning",
  securityLevel: SecurityLevel.JobCoach,
  viewMode: ViewMode.Wide,
  component: PlanningPage,
};

export const OFFICE_LIST_ROUTE = {
  path: "/offices",
  nameKey: "route.offices",
  securityLevel: SecurityLevel.CompanyAdmin,
  viewMode: ViewMode.Wide,
  component: OfficeListPage,
};

export const OFFICE_DETAILS_ROUTE = {
  path: "/offices/details/:id",
  nameKey: "route.office",
  securityLevel: SecurityLevel.CompanyAdmin,
  viewMode: ViewMode.Wide,
  component: OfficeDetailsPage,
};

export const JOB_COACH_LIST_ROUTE = {
  path: "/job-coaches",
  nameKey: "route.job_coaches",
  securityLevel: SecurityLevel.CompanyAdmin,
  viewMode: ViewMode.Wide,
  component: JobCoachListPage,
};

export const JOB_COACH_DETAILS_ROUTE = {
  path: "/job-coaches/details/:id",
  nameKey: "route.profile",
  securityLevel: SecurityLevel.JobCoach,
  viewMode: ViewMode.Wide,
  component: JobCoachDetailsPage,
};

export const COMPANY_CREATE_ROUTE = {
  path: "/admin-create",
  nameKey: "route.company_create",
  securityLevel: SecurityLevel.CompanyAdmin,
  viewMode: ViewMode.Compact,
  component: CompanyCreatePage,
};

export const FORGOT_PASSWORD_ROUTE = {
  path: "/forgot-password",
  nameKey: "route.forgot_password",
  securityLevel: SecurityLevel.None,
  viewMode: ViewMode.Compact,
  component: ForgotPasswordPage,
};

export const CHANGE_PASSWORD_ROUTE = {
  path: "/change-password",
  nameKey: "route.change_password",
  securityLevel: SecurityLevel.JobCoach,
  viewMode: ViewMode.Compact,
  component: ChangePasswordPage,
};

export const SETTINGS_ROUTE = {
  path: "/settings",
  nameKey: "route.settings",
  securityLevel: SecurityLevel.CompanyAdmin,
  viewMode: ViewMode.Compact,
  component: SettingsPage,
};

export const ANALYTICS_ROUTE = {
  path: "/analytics",
  nameKey: "route.analytics",
  securityLevel: SecurityLevel.CompanyAdmin,
  viewMode: ViewMode.Wide,
  component: AnalyticsPage,
};

export const RESET_PASSWORD_ROUTE = {
  path: "/reset-password",
  nameKey: "route.reset_password",
  component: ResetPasswordPage,
  icon: undefined,
};

export const maijaRoutes: MaijaRoute[] = [
  ANALYTICS_ROUTE,
  APPLICANT_LIST_ROUTE,
  APPLICANT_DETAILS_ROUTE,
  CREATE_APPLICANT_ROUTE,
  COMPANY_CREATE_ROUTE,
  PLANNING_ROUTE,
  OFFICE_LIST_ROUTE,
  JOB_COACH_LIST_ROUTE,
  JOB_COACH_DETAILS_ROUTE,
  OFFICE_DETAILS_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  SETTINGS_ROUTE,
];

export function getMaijaRoutes(): MaijaRoute[] {
  return maijaRoutes;
}

export function navigateToApplicantDetails(navigateFunction: NavigateFunction, id: string) {
  navigateFunction(`/applicants/details/${id}`);
}

export function navigateToOfficeDetails(navigateFunction: NavigateFunction, id: string) {
  navigateFunction(`/offices/details/${id}`);
}

export function navigateToJobCoachDetails(navigateFunction: NavigateFunction, id: string) {
  navigateFunction(`/job-coaches/details/${id}`);
}

export default MaijaRoute;
