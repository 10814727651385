import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Box,
  CardHeader,
  CardContent,
  Typography,
  ListSubheader,
  AppBar,
  Tabs,
  Tab,
  List,
  Paper,
  ListItemButton,
  Fab,
  Card,
  Zoom,
} from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { ConfirmActionDialog } from "../../components/ConfirmActionDialog";
import { useTheme } from "@mui/material/styles";
import { Meeting, MeetingType } from "../../types/Meeting";
import {
  fetchMeetings,
  fetchSendOutTimes,
  fetchSendOuts,
  fetchApplicantNames,
  groupMeetingsByDay,
  groupSendOutsByDay,
  deleteMeeting,
  deleteSendOutTime,
} from "./PlanningRepository";
import { SendOutTime } from "../../types/SendOutTime";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { navigateToApplicantDetails } from "../../Routes";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import {
  setMeetings,
  setSendOutTimes,
  setSendouts,
  setApplicantNames,
  removeMeeting as removeMeetingFromState,
  setSelectedTab,
  setSelectedMeeting,
  setSelectedSendOutTime,
} from "../../reducers/planningPageSlice";
import { BookingModal, BookingMode, BookingType } from "./BookModal";
import MaijaContainer from "../../components/MaijaContainer";
import { useTranslation } from "react-i18next";

export enum PlanningPageTab {
  MeetingsTab,
  SendoutsTab,
}

const PlanningPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    fetchMeetings().then((data) => dispatch(setMeetings(data)));
    fetchSendOutTimes().then((data) => dispatch(setSendOutTimes(data)));
    fetchSendOuts().then((data) => dispatch(setSendouts(data || [])));
    fetchApplicantNames().then((data) => dispatch(setApplicantNames(data)));
  }, [dispatch]);

  const meetings = useAppSelector((state) => state.planningPageSlice.meetings);
  const sendoutTimes = useAppSelector((state) => state.planningPageSlice.sendoutTimes);
  const selectedMeeting = useAppSelector((state) => state.planningPageSlice.selectedMeeting);
  const selectedSendOutTime = useAppSelector((state) => state.planningPageSlice.selectedSendOutTime);
  const tab = useAppSelector((state) => state.planningPageSlice.selectedTab);

  const setPlanningTab = (newTab: PlanningPageTab) => {
    dispatch(setSelectedTab(newTab));
  };

  return (
    <MaijaContainer>
      <FabComponent tab={tab} />
      <Typography variant="h4" gutterBottom>
        {t("planningPage.title")}
      </Typography>
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          value={tab}
          onChange={(event, newValue) => setPlanningTab(newValue)}
          indicatorColor="primary"
          textColor="inherit"
        >
          <Tab label={t("planningPage.meetingsTab")} />
          <Tab label={t("planningPage.sendoutsTab")} />
        </Tabs>
      </AppBar>
      {tab === PlanningPageTab.MeetingsTab && (
        <MeetingsTab
          meetings={meetings}
          selectedMeeting={selectedMeeting}
          setSelectedMeeting={(meeting) => {
            dispatch(setSelectedMeeting(meeting));
          }}
        />
      )}
      {tab === PlanningPageTab.SendoutsTab && (
        <SendOutTab
          sendOutTimes={sendoutTimes}
          selectedSendOutTime={selectedSendOutTime}
          setSelectedSendOut={(time) => {
            dispatch(setSelectedSendOutTime(time));
          }}
        />
      )}
    </MaijaContainer>
  );
};

interface MeetingsTabProps {
  meetings: Meeting[];
  selectedMeeting: Meeting | undefined;
  setSelectedMeeting: (meeting: Meeting | undefined) => void;
}

const MeetingsTab: React.FC<MeetingsTabProps> = ({ meetings, selectedMeeting, setSelectedMeeting }) => {
  return (
    <Box display="flex" justifyContent="space-between" sx={{ pt: 5 }}>
      <MeetingsList meetings={meetings} setSelectedMeeting={setSelectedMeeting} />
      <Card sx={{ p: 5, position: "sticky", top: 30 }} style={{ width: "48%", alignSelf: "baseline" }}>
        {selectedMeeting ? (
          <MeetingCard meeting={selectedMeeting} setSelectedMeeting={setSelectedMeeting} />
        ) : (
          <EmptyMeetingCard />
        )}
      </Card>
    </Box>
  );
};

interface MeetingsListProps {
  meetings: Meeting[];
  setSelectedMeeting: (meeting: Meeting | undefined) => void;
}

const MeetingsList: React.FC<MeetingsListProps> = ({ meetings, setSelectedMeeting }) => {
  const groupedMeetings = groupMeetingsByDay(meetings);
  return (
    <List component={Paper} style={{ width: "48%" }}>
      {Object.keys(groupedMeetings).map((dateStr) => (
        <li key={dateStr}>
          <ListSubheader>{formatDate(dateStr)}</ListSubheader>
          {groupedMeetings[dateStr].map((meeting, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                setSelectedMeeting(meeting);
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{ pr: 15 }}
                style={{ width: "100%" }}
                data-cy="meetings-list"
              >
                <Typography>
                  {formatTime(meeting.startTime)} - {formatTime(meeting.endTime)}
                </Typography>
                <Typography>{meeting.applicantFullName}</Typography>
                <Typography>{/* {MeetingType[meeting.type]} */}</Typography>
              </Box>
            </ListItemButton>
          ))}
        </li>
      ))}
    </List>
  );
};

interface MeetingCardProps {
  meeting: Meeting;
  setSelectedMeeting: (meeting: Meeting | undefined) => void;
}

const MeetingCard: React.FC<MeetingCardProps> = ({ meeting, setSelectedMeeting }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [removeMeetingModal, setRemoveMeetingModal] = React.useState(false);
  const [bookingModal, setBookingModal] = useState(false);

  const handleRemoveMeeting = async (meetingId: string) => {
    try {
      dispatch(removeMeetingFromState(meetingId));

      await deleteMeeting(meetingId);

      setSelectedMeeting(undefined);
      dispatch(
        showSnackbar({
          message: t("planningPage.meetingRemoved"),
        }),
      );
      setRemoveMeetingModal(false);
    } catch (error) {
      console.error("Error removing meeting:", error);
      dispatch(
        showSnackbar({
          message: t("planningPage.removeMeetingError"),
          severity: "error",
        }),
      );
    }
  };

  return (
    <div>
      <ConfirmActionDialog
        open={removeMeetingModal}
        title={t("planningPage.removeMeetingConfirm")}
        description={`${t("planningPage.removeMeetingDescription")} ${meeting.applicantFullName}`}
        onClose={() => {
          setRemoveMeetingModal(false);
        }}
        onAction={() => handleRemoveMeeting(meeting.id)}
      />
      <BookingModal
        booking={{ mode: BookingMode.Edit, type: BookingType.Meeting, meeting: meeting }}
        isOpen={bookingModal}
        handleClose={() => {
          setBookingModal(false);
        }}
      />

      <CardHeader
        title={
          meeting?.type === MeetingType.Physical ? t("planningPage.physicalMeeting") : t("planningPage.virtualMeeting")
        }
      />
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Avatar alt={meeting.applicantFullName} src={meeting.applicantIconUrl} />
            <Box sx={{ ml: 4 }}>
              <Typography variant="h6">{meeting.applicantFullName}</Typography>
              <Typography variant="body2">
                {formatTime(meeting.startTime)} - {formatTime(meeting.endTime)}
              </Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigateToApplicantDetails(navigate, meeting.applicantId);
            }}
          >
            {t("planningPage.seeDetails")}
          </Button>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Typography variant="body1" fontWeight={"bold"}>
            {t("planningPage.applicantIntroduction")}
          </Typography>
          <Typography variant="body2" sx={{ mb: 5 }}>
            {meeting.applicantIntroduction}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" sx={{ mt: 6 }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "50px" }}
            onClick={() => {
              setBookingModal(true);
            }}
          >
            {t("planningPage.edit")}
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "50px" }}
            onClick={() => {
              setRemoveMeetingModal(true);
            }}
          >
            {t("planningPage.delete")}
          </Button>
        </Box>
      </CardContent>
    </div>
  );
};

const EmptyMeetingCard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      minHeight="200px"
      padding={4}
    >
      <GroupsIcon style={{ fontSize: 80 }} />
      <Typography variant="h6" gutterBottom>
        {t("planningPage.chooseMeeting")}
      </Typography>
    </Box>
  );
};

interface SendOutTabProps {
  sendOutTimes: SendOutTime[];
  selectedSendOutTime: SendOutTime | undefined;
  setSelectedSendOut: (sendOut: SendOutTime | undefined) => void;
}

const SendOutTab: React.FC<SendOutTabProps> = ({ sendOutTimes, selectedSendOutTime, setSelectedSendOut }) => {
  return (
    <Box display="flex" justifyContent="space-between" sx={{ pt: 5 }}>
      <SendOutList sendOutTimes={sendOutTimes} setSelectedSendOut={setSelectedSendOut} />
      <Card sx={{ p: 5, position: "sticky", top: 30 }} style={{ width: "48%", alignSelf: "baseline" }}>
        {selectedSendOutTime ? (
          <SendOutsCard sendOutTime={selectedSendOutTime} setSelectedSendOut={setSelectedSendOut} />
        ) : (
          <EmptySendOutsCard />
        )}
      </Card>
    </Box>
  );
};

interface SendOutListProps {
  sendOutTimes: SendOutTime[];
  setSelectedSendOut: (sendOut: SendOutTime | undefined) => void;
}

const SendOutList: React.FC<SendOutListProps> = ({ sendOutTimes, setSelectedSendOut }) => {
  const groupedSendOuts = groupSendOutsByDay(sendOutTimes);
  return (
    <List component={Paper} style={{ width: "48%" }}>
      {Object.keys(groupedSendOuts).map((dateStr) => (
        <li key={dateStr}>
          <ListSubheader style={{ zIndex: 1000 }}>{formatDate(dateStr)}</ListSubheader>
          {groupedSendOuts[dateStr].map((sendOut, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                setSelectedSendOut(sendOut);
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{ pr: 15 }}
                style={{ width: "100%" }}
              >
                <Typography>{sendOut.applicantFullName}</Typography>
                <Typography>{sendOut.sendOut.title}</Typography>
                <Typography></Typography>
              </Box>
            </ListItemButton>
          ))}
        </li>
      ))}
    </List>
  );
};

interface SendOutsCardProps {
  sendOutTime: SendOutTime;
  setSelectedSendOut: (sendOut: SendOutTime | undefined) => void;
}

const SendOutsCard: React.FC<SendOutsCardProps> = ({ sendOutTime, setSelectedSendOut }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [removeSendOutModal, setRemoveSendOutModal] = React.useState(false);
  const [bookingModal, setBookingModal] = useState(false);

  const handleRemoveSendOut = async (sendOutTimeId: string) => {
    try {
      await deleteSendOutTime(sendOutTimeId);

      setSelectedSendOut(undefined);

      const data = await fetchSendOutTimes();
      dispatch(setSendOutTimes(data));

      dispatch(
        showSnackbar({
          message: t("planningPage.sendoutRemoved"),
        }),
      );

      setRemoveSendOutModal(false);
    } catch (error) {
      console.error("Error removing send out:", error);
      dispatch(
        showSnackbar({
          message: t("planningPage.removeSendoutError"),
          severity: "error",
        }),
      );
    }
  };

  return (
    <div>
      <ConfirmActionDialog
        open={removeSendOutModal}
        title={t("planningPage.removeSendoutConfirm")}
        description={`${t("planningPage.removeSendoutDescription")} ${sendOutTime.sendOut.title} to ${sendOutTime.applicantFullName}`}
        onClose={() => {
          setRemoveSendOutModal(false);
        }}
        onAction={() => handleRemoveSendOut(sendOutTime.id)}
      />

      <BookingModal
        booking={{ mode: BookingMode.Edit, type: BookingType.SendOut, sendOutTime: sendOutTime }}
        isOpen={bookingModal}
        handleClose={() => {
          setBookingModal(false);
        }}
      />

      <CardHeader title={sendOutTime.sendOut.title} subheader={`Will be sent at ${formatTime(sendOutTime.sendTime)}`} />
      <CardContent sx={{ mt: -2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Avatar alt={sendOutTime.applicantFullName} src={sendOutTime.applicantIconUrl} />
            <Box sx={{ ml: 4 }}>
              <Typography variant="h6">{sendOutTime.applicantFullName}</Typography>
              <Typography variant="body2">{formatTime(sendOutTime.sendTime)}</Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigateToApplicantDetails(navigate, sendOutTime.applicantId);
            }}
          >
            {t("planningPage.seeDetails")}
          </Button>
        </Box>
        <Box display="flex" flexDirection="row" sx={{ mt: 6 }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "50px" }}
            onClick={() => {}}
          >
            {t("planningPage.download")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "50px" }}
            onClick={() => {
              setBookingModal(true);
            }}
          >
            {t("planningPage.edit")}
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "50px" }}
            onClick={() => {
              setRemoveSendOutModal(true);
            }}
          >
            {t("planningPage.remove")}
          </Button>
        </Box>
      </CardContent>
    </div>
  );
};

const EmptySendOutsCard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      minHeight="200px"
      padding={4}
    >
      <AssignmentIcon style={{ fontSize: 80 }} />
      <Typography variant="h6" gutterBottom>
        {t("planningPage.chooseSendout")}
      </Typography>
    </Box>
  );
};

interface FabComponentProps {
  tab: PlanningPageTab;
}

const FabComponent: React.FC<FabComponentProps> = ({ tab }) => {
  const { t } = useTranslation();
  const [fabAnimationValue, setFabAnimationValue] = useState<PlanningPageTab>(PlanningPageTab.MeetingsTab);
  const [bookingModal, setBookingModal] = useState(false);

  useEffect(() => {
    setFabAnimationValue(tab);
  }, [tab]);

  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const bookingType = tab === PlanningPageTab.MeetingsTab ? BookingType.Meeting : BookingType.SendOut;

  return (
    <Box>
      <BookingModal
        booking={{ mode: BookingMode.Create, type: bookingType }}
        isOpen={bookingModal}
        handleClose={() => {
          setBookingModal(false);
        }}
      />

      <Zoom
        key={tab}
        in={fabAnimationValue === tab}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${fabAnimationValue === tab ? transitionDuration.exit : 0}ms`,
        }}
        unmountOnExit
      >
        <Fab
          variant="extended"
          color="secondary"
          style={{
            position: "fixed",
            bottom: "60px",
            right: "96px",
          }}
          onClick={() => setBookingModal(true)}
        >
          <AddIcon sx={{ mr: 1 }} />
          <>
            {tab === PlanningPageTab.MeetingsTab && (
              <Typography variant="body2" data-cy="add-meeting">
                {t("planningPage.addMeeting")}
              </Typography>
            )}
            {tab === PlanningPageTab.SendoutsTab && (
              <Typography variant="body2">{t("planningPage.addSendout")}</Typography>
            )}
          </>
        </Fab>
      </Zoom>
    </Box>
  );
};

const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const formattedDate = date.toLocaleDateString("sv-SE", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });

  return formattedDate;
};

const formatTime = (dateStr: string): string => {
  const date = new Date(dateStr);
  return date.toLocaleTimeString("se-SE", { hour: "2-digit", minute: "2-digit" });
};

export default PlanningPage;
