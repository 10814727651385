import { OfficeDetails } from "../../types/OfficeDetails";
import axiosInstance from "../../utils/AxiosInstance";
import { StatusReport } from "../../types/StatusReport";
import { AxiosResponse } from "axios";

export async function getOfficeDetails(officeId: string): Promise<OfficeDetails | undefined> {
  try {
    const response = await axiosInstance.get("/office/details", {
      params: {
        office_id: officeId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const getOfficeReport = async (officeId: string): Promise<StatusReport> => {
  const response: AxiosResponse<StatusReport> = await axiosInstance.get("/office/report", {
    params: {
      office_id: officeId,
    },
  });

  return response.data;
};

export async function removeOffice(officeId: string): Promise<boolean> {
  try {
    await axiosInstance.post("/office/delete", {
      office_id: officeId,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
