import { ApplicantName } from "../../types/ApplicantName";
import { Meeting, MeetingType } from "../../types/Meeting";
import { SendOutTime } from "../../types/SendOutTime";
import axiosInstance from "../../utils/AxiosInstance";
import store from "../../store";
import { formatDateToString } from "../../utils/DateUtils";
import { SendOut } from "../../types/SendOut";

export async function fetchMeetings(): Promise<Meeting[]> {
  const jobCoachId = store.getState().user.user?.jobCoachId;
  try {
    const response = await axiosInstance.get("/meeting/list", {
      params: {
        job_coach_id: jobCoachId,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export interface MeetingRequestData {
  applicantId: string;
  startTime: string;
  endTime: string;
  type: MeetingType;
}

export async function bookMeeting(data: MeetingRequestData): Promise<void> {
  try {
    const jobCoachId = store.getState().user.user?.jobCoachId;
    const requestData = {
      applicant_id: data.applicantId,
      start_time: data.startTime,
      end_time: data.endTime,
      type: data.type,
      job_coach_id: jobCoachId,
    };
    await axiosInstance.post("/meeting/create", requestData);
  } catch (error) {
    console.log(error);
  }
}

export async function editMeeting(meetingId: string, data: MeetingRequestData): Promise<void> {
  try {
    const requestData = {
      meeting_id: meetingId,
      applicant_id: data.applicantId,
      start_time: data.startTime,
      end_time: data.endTime,
      type: data.type,
    };
    await axiosInstance.post(`/meeting/edit`, requestData);
  } catch (error) {
    console.log(error);
  }
}

export async function deleteMeeting(meetingId: string): Promise<void> {
  try {
    await axiosInstance.post(`/meeting/delete`, {
      meeting_id: meetingId,
    });
  } catch (error) {
    console.log(error);
  }
}

export async function fetchApplicantNames(): Promise<ApplicantName[]> {
  try {
    const jobCoachId = store.getState().user.user?.jobCoachId;
    const response = await axiosInstance.get("/applicant/names", {
      params: {
        job_coach_id: jobCoachId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function fetchSendOutTimes(): Promise<SendOutTime[]> {
  try {
    const jobCoachId = store.getState().user.user?.jobCoachId;
    const response = await axiosInstance.get(`/sendout_time/list`, {
      params: { job_coach_id: jobCoachId },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export interface SendOutTimeRequestData {
  applicantId: string;
  sendOutId: string;
  sendTime: string;
}

export async function bookSendOutTime(data: SendOutTimeRequestData): Promise<void> {
  try {
    const jobCoachId = store.getState().user.user?.jobCoachId;
    const requestData = {
      job_coach_id: jobCoachId,
      applicant_id: data.applicantId,
      sendout_id: data.sendOutId,
      send_time: data.sendTime,
    };
    console.log("Booking sendout time with data:", requestData);
    await axiosInstance.post(`/sendout_time/create`, requestData);
  } catch (error) {
    console.log(error);
  }
}

export async function editSendOutTime(sendOutTimeId: string, data: SendOutTimeRequestData): Promise<void> {
  try {
    const requestData = {
      send_out_time_id: sendOutTimeId,
      applicant_id: data.applicantId,
      sendout_id: data.sendOutId,
      send_time: data.sendTime,
    };
    await axiosInstance.post(`/sendout_time/edit`, requestData);
  } catch (error) {
    console.log(error);
  }
}

export async function deleteSendOutTime(sendOutTimeId: string): Promise<void> {
  try {
    await axiosInstance.post(`/sendout_time/delete`, {
      send_out_time_id: sendOutTimeId,
    });
  } catch (error) {
    console.log(error);
  }
}

export async function fetchSendOuts(): Promise<SendOut[] | null> {
  try {
    const response = await axiosInstance.get(`/sendouts`);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export const groupMeetingsByDay = (meetings: Meeting[]): Record<string, Meeting[]> => {
  const groupedMeetings: Record<string, Meeting[]> = {};

  meetings.forEach((meeting) => {
    // Convert the meeting's start time to a string in the format "YYYY-MM-DD"
    const dateStr = formatDateToString(meeting.startTime);

    // Initialize the array for the date if it doesn't exist
    if (!groupedMeetings[dateStr]) {
      groupedMeetings[dateStr] = [];
    }

    // Add the meeting to the array for its date
    groupedMeetings[dateStr].push(meeting);
  });

  const entries = Object.entries(groupedMeetings);
  const sortedEntries = entries.sort(([a], [b]) => b.localeCompare(a));
  const sortedGroupedMeetings: Record<string, Meeting[]> = Object.fromEntries(sortedEntries);

  return sortedGroupedMeetings;
};

export const groupSendOutsByDay = (sendOuts: SendOutTime[]): Record<string, SendOutTime[]> => {
  const groupedSendOuts: Record<string, SendOutTime[]> = {};

  sendOuts.forEach((sendOut) => {
    // Convert the meeting's start time to a string in the format "YYYY-MM-DD"
    // const dateStr = sendOut.sendTime.split('T')[0];
    const dateStr = formatDateToString(sendOut.sendTime);
    // Initialize the array for the date if it doesn't exist
    if (!groupedSendOuts[dateStr]) {
      groupedSendOuts[dateStr] = [];
    }

    // Add the meeting to the array for its date
    groupedSendOuts[dateStr].push(sendOut);
  });

  const entries = Object.entries(groupedSendOuts);
  const sortedEntries = entries.sort(([a], [b]) => b.localeCompare(a));
  const sortedGroupedSendOuts: Record<string, SendOutTime[]> = Object.fromEntries(sortedEntries);

  return sortedGroupedSendOuts;
};
