import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../hooks";
import { showSnackbar } from "../reducers/snackbarSlice";
import { CircularProgress, Typography, TextField, Button, Box } from "@mui/material";

import MaijaModal from "./MaijaModal";

export interface MaijaSendEmailModalProps {
  sendToName: string;
  isOpen: boolean;
  onSendEmail: (subject: string, body: string) => Promise<boolean>;
  onSuccess: () => void;
  handleClose: () => void;
}

const MaijaSendEmailModal: React.FC<MaijaSendEmailModalProps> = ({
  sendToName,
  isOpen,
  onSuccess,
  handleClose,
  onSendEmail,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <MaijaModal isOpen={isOpen} maxWidth="800px" handleClose={handleClose}>
      <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
        <Typography variant="h5" alignSelf="flex-start">
          {t("maijaSendEmailModal.send_email")} {sendToName}
        </Typography>
        <TextField
          label={t("maijaSendEmailModal.subject_label")}
          variant="outlined"
          margin="normal"
          fullWidth
          style={{ maxWidth: "800px" }}
          sx={{ my: 4 }}
          value={subject}
          onChange={(e) => {
            setSubject(e.target.value);
          }}
        />
        <TextField
          label={t("maijaSendEmailModal.body_label")}
          variant="outlined"
          margin="normal"
          multiline={true}
          minRows={8}
          fullWidth
          style={{ maxWidth: "800px" }}
          sx={{ mt: 2 }}
          value={body}
          onChange={(e) => {
            setBody(e.target.value);
          }}
        />
        <Button
          variant="contained"
          style={{ minWidth: "200px" }}
          sx={{ mt: 4 }}
          disabled={isLoading}
          onClick={() => {
            setIsLoading(true);
            onSendEmail(subject, body)
              .then((success) => {
                handleClose();
                setSubject("");
                setBody("");
                if (success) {
                  onSuccess();
                } else {
                  dispatch(
                    showSnackbar({
                      message: t("maijaSendEmailModal.error_message"),
                    }),
                  );
                }
              })
              .catch((errorMessage) => {
                console.log(errorMessage);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        >
          {t("maijaSendEmailModal.send_button")} {isLoading && <CircularProgress size={22} sx={{ ml: 3 }} />}
        </Button>
      </Box>
    </MaijaModal>
  );
};

export default MaijaSendEmailModal;
