import { ApplicantActionPlan, ApplicantReport } from "../../types/ApplicantReport";
import axiosInstance from "../../utils/AxiosInstance";
import { AxiosResponse } from "axios";

export const getApplicantReport = async (applicantId: string, reportId: string): Promise<ApplicantReport> => {
  const response: AxiosResponse<ApplicantReport> = await axiosInstance.get("/applicant/report", {
    params: {
      applicant_id: applicantId,
      report_id: reportId,
    },
  });

  console.log(response.data);

  return response.data;
};

export async function getApplicantReports(applicantId: string): Promise<ApplicantReport[]> {
  try {
    const response = await axiosInstance.get("/applicant/reports", {
      params: {
        applicant_id: applicantId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export const getNewApplicantReport = async (applicantId: string): Promise<ApplicantReport> => {
  const response: AxiosResponse<ApplicantReport> = await axiosInstance.get("/applicant/report_create", {
    params: {
      applicant_id: applicantId,
    },
  });

  console.log(response.data);

  return response.data;
};

export const sendApplicantReportEmail = async (applicantId: string, reportId: string): Promise<string> => {
  try {
    const response: AxiosResponse<string> = await axiosInstance.get("/applicant/report/email", {
      params: {
        applicant_id: applicantId,
        report_id: reportId,
      },
    });

    console.log(response.data);

    return response.data;
  } catch (error) {
    console.error("Error sending applicant report email:", error);
    throw error;
  }
};

export const generateApplicantActionPlan = async (
  applicantId: string,
  reportId: string,
  language: string,
): Promise<ApplicantActionPlan> => {
  try {
    const response: AxiosResponse<ApplicantActionPlan> = await axiosInstance.get("/applicant/report/action_plan", {
      params: {
        applicant_id: applicantId,
        report_id: reportId,
        language: language,
      },
    });

    console.log(response.data);

    return response.data;
  } catch (error) {
    console.error("Error sending applicant report email:", error);
    throw error;
  }
};
