import { Navigate } from "react-router-dom";
import { useAppDispatch } from "../hooks";
import MaijaRoute, {
  APPLICANT_LIST_ROUTE,
  CREATE_APPLICANT_ROUTE,
  PLANNING_ROUTE,
  OFFICE_LIST_ROUTE,
  JOB_COACH_LIST_ROUTE,
  COMPANY_CREATE_ROUTE,
  ViewMode,
  JOB_COACH_DETAILS_ROUTE,
  ANALYTICS_ROUTE,
  LOGIN_ROUTE,
} from "../Routes";
import MaijaAppBar from "./MaijaAppBar";
import { showSnackbar } from "./../reducers/snackbarSlice";
import { SecurityLevel, User } from "../types/User";
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../providers/AuthProvider";
import axiosInstance from "../utils/AxiosInstance";
import { setLoggedInUser } from "../reducers/userSlice";
import { getSettings } from "../pages/settings/SettingsRepository";
import {
  setPrimaryColor as setReduxPrimaryColor,
  setSecondaryColor as setReduxSecondaryColor,
  setTertiaryColor as setReduxTertiaryColor,
  setLogo as setReduxLogo,
  setLoginImage as setReduxLoginImage,
  setSettingsLoaded as setReduxSettingsLoaded,
} from "../reducers/themeSlice";
import MaijaLoadingPage from "./MaijaLoadingPage";

type ProtectedRouteProps = {
  route: MaijaRoute;
  children: React.ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ route, children }) => {
  const { user, setUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchAndSetCompanySettings = async (companyId: string | undefined) => {
      if (!companyId) {
        dispatch(setReduxSettingsLoaded(true));
        return;
      }
      try {
        const settings = await getSettings(companyId);
        if (settings) {
          dispatch(setReduxPrimaryColor(settings.primary_color || "#FFFFFF"));
          dispatch(setReduxSecondaryColor(settings.secondary_color || "#FFFFFF"));
          dispatch(setReduxTertiaryColor(settings.tertiary_color || "#FFFFFF"));
          dispatch(setReduxLogo(settings.logo ?? null));
          dispatch(setReduxLoginImage(settings.login_image ?? null));
        }
      } catch (error) {
        console.error("Failed to load company settings:", error);
      } finally {
        dispatch(setReduxSettingsLoaded(true));
      }
    };

    const fetchCurrentUser = async () => {
      try {
        const response = await axiosInstance.get<User>("/current_user");
        setUser(response.data);
        dispatch(setLoggedInUser(response.data));
        sessionStorage.setItem("currentUser", JSON.stringify(response.data));
        await fetchAndSetCompanySettings(response.data.companyId);
      } catch (error) {
        setUser(null);
        dispatch(setReduxSettingsLoaded(true));
      } finally {
        setLoading(false);
      }
    };

    if (!user) {
      fetchCurrentUser();
    } else {
      fetchAndSetCompanySettings(user.companyId);
      setLoading(false);
    }
  }, [user, setUser, dispatch]);

  if (loading) {
    return <MaijaLoadingPage isFullscreen />;
  }

  if (!user) {
    return <Navigate to={LOGIN_ROUTE.path} replace />;
  }

  if (route.securityLevel && user.securityLevel < route.securityLevel) {
    dispatch(
      showSnackbar({
        message: t("generic.error"),
        severity: "error",
      }),
    );
    return <Navigate to={APPLICANT_LIST_ROUTE.path} replace />;
  }

  let toolbarRoutes = [];

  if (user.securityLevel >= SecurityLevel.CompanyAdmin) {
    toolbarRoutes = [
      OFFICE_LIST_ROUTE,
      JOB_COACH_LIST_ROUTE,
      APPLICANT_LIST_ROUTE,
      PLANNING_ROUTE,
      COMPANY_CREATE_ROUTE,
      ANALYTICS_ROUTE,
    ];
  } else {
    toolbarRoutes = [JOB_COACH_DETAILS_ROUTE, APPLICANT_LIST_ROUTE, PLANNING_ROUTE, CREATE_APPLICANT_ROUTE];
  }

  const containerStyle = {
    height: "100vh",
    minWidth: route.viewMode === ViewMode.Wide ? "1000px" : "100%",
    margin: "0 auto",
  };

  return (
    <div style={containerStyle}>
      <MaijaAppBar routes={toolbarRoutes} />
      <div style={{ height: "100vh" }}>{children}</div>
    </div>
  );
};

export default ProtectedRoute;
