import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { JobCoachDetails } from "../types/JobCoachDetails";

interface JobCoachDetailsPageState {
  jobCoachesMap: { [id: string]: JobCoachDetails };
}

const initialState: JobCoachDetailsPageState = {
  jobCoachesMap: {},
};

export const jobCoachDetailsPageSlice = createSlice({
  name: "jobCoachDetails",
  initialState,
  reducers: {
    pushJobCoach: (state, action: PayloadAction<JobCoachDetails>) => {
      state.jobCoachesMap[action.payload.jobCoachId] = action.payload;
    },
    clearJobCoachDetailsPageSlice: (state) => {
      state.jobCoachesMap = {};
    },
  },
});

export const { pushJobCoach, clearJobCoachDetailsPageSlice } = jobCoachDetailsPageSlice.actions;
export const jobCoachDetailsPage = (state: RootState) => state.applicantDetailsPageSlice;
export default jobCoachDetailsPageSlice.reducer;
