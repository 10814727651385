import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { ApplicantDetails } from "../types/ApplicantDetails";
import { ApplicantReportListItem } from "../types/ApplicantReport";
import { ApplicantSupportInfo } from "../types/ApplicantSupportInfo";

interface ApplicantDetailsPageState {
  applicantsMap: { [id: string]: ApplicantDetails };
  reportListItemsMap: { [id: string]: ApplicantReportListItem[] };
  supportInfoItemsMap: { [id: string]: ApplicantSupportInfo };
}

const initialState: ApplicantDetailsPageState = {
  applicantsMap: {},
  reportListItemsMap: {},
  supportInfoItemsMap: {},
};

export const applicantDetailsPageSlice = createSlice({
  name: "applicantDetails",
  initialState,
  reducers: {
    setApplicant: (state, action: PayloadAction<ApplicantDetails>) => {
      state.applicantsMap[action.payload.applicantId] = action.payload;
    },
    setApplicantDetailsReportsList: (
      state,
      action: PayloadAction<{
        applicantId: string;
        reports: ApplicantReportListItem[];
      }>,
    ) => {
      state.reportListItemsMap[action.payload.applicantId] = action.payload.reports;
    },
    addApplicantDetailsReportsList: (
      state,
      action: PayloadAction<{
        applicantId: string;
        reports: ApplicantReportListItem;
      }>,
    ) => {
      if (state.reportListItemsMap[action.payload.applicantId]) {
        state.reportListItemsMap[action.payload.applicantId].push(action.payload.reports);
      } else {
        state.reportListItemsMap[action.payload.applicantId] = [action.payload.reports];
      }
    },
    setApplicantSupportInfoList: (
      state,
      action: PayloadAction<{
        applicantId: string;
        supportInfo: ApplicantSupportInfo;
      }>,
    ) => {
      state.supportInfoItemsMap[action.payload.applicantId] = action.payload.supportInfo;
    },
    addApplicantSupportInfoItem: (
      state,
      action: PayloadAction<{
        applicantId: string;
        supportInfo: ApplicantSupportInfo;
      }>,
    ) => {
      state.supportInfoItemsMap[action.payload.applicantId] = action.payload.supportInfo;
    },
    clearApplicantDetailsPage: (state) => {
      state.applicantsMap = {};
      state.reportListItemsMap = {};
      state.supportInfoItemsMap = {};
    },
  },
});

export const {
  setApplicant,
  setApplicantDetailsReportsList,
  addApplicantDetailsReportsList,
  setApplicantSupportInfoList,
  addApplicantSupportInfoItem,
  clearApplicantDetailsPage,
} = applicantDetailsPageSlice.actions;

export const applicantDetailsPage = (state: RootState) => state.applicantDetailsPageSlice;

export default applicantDetailsPageSlice.reducer;
