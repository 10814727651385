import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Typography, Button, Box, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ArticleIcon from "@mui/icons-material/Article";
import MaijaContainer from "../../components/MaijaContainer";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";
import MaijaErrorPage from "../../components/MaijaErrorPage";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getOfficeDetails, getOfficeReport, removeOffice } from "./OfficeDetailsRepository";
import { setOffice } from "../../reducers/officeDetailsPageSlice";
import MaijaInfoCard from "../../components/MaijaInfoCard";
import MaijaModal from "../../components/MaijaModal";
import MaijaAsyncComponent from "../../components/MaijaAsyncComponent";
import { StatusReport } from "../../types/StatusReport";
import { ConfirmActionDialog } from "../../components/ConfirmActionDialog";
import { COMPANY_CREATE_ROUTE, OFFICE_LIST_ROUTE } from "../../Routes";
import { useTranslation } from "react-i18next";
import { setCompanyCreateSelectedOffice, setCompanyCreateSelectedTab } from "../../reducers/companyCreatePageSlice";
import { CompanyCreatePageTab } from "../company-create/CompanyCreatePage";
import { officeDetailsToOfficeItem } from "../../types/OfficeDetails";
import { People, SupervisedUserCircle } from "@mui/icons-material";
import JobCoachList from "../../components/JobCoachList";

const OfficeDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const office = useAppSelector((state) => (id ? state.officeDetailsPageSlice.officesMap[id] : undefined));
  const [isLoading, setIsLoading] = useState(true);

  const [officeReportModalOpen, setOfficeReportModalOpen] = React.useState(false);
  const [removeOfficeModalOpen, setRemoveOfficeModalOpen] = React.useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const handleRemoveOffice = async (officeId: string) => {
    try {
      const success = await removeOffice(officeId);
      if (success) {
        navigate(OFFICE_LIST_ROUTE.path);
        dispatch(
          showSnackbar({
            message: t("officeDetails.officeRemoved"),
          }),
        );
      } else {
        dispatch(
          showSnackbar({
            message: t("officeDetails.failedToRemoveOffice"),
            severity: "error",
          }),
        );
      }
    } catch (error) {
      console.error("Error removing office:", error);
      dispatch(
        showSnackbar({
          message: t("officeDetails.failedToRemoveOffice"),
          severity: "error",
        }),
      );
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    setIsLoading(true);
    getOfficeDetails(id)
      .then((data) => {
        if (data) {
          dispatch(setOffice(data));
        }
      })
      .finally(() => setIsLoading(false));
  }, [id, dispatch]);

  if (isLoading) {
    return (
      <MaijaContainer style={{ height: "100%" }}>
        <MaijaLoadingPage isFullscreen={true} />
      </MaijaContainer>
    );
  }

  if (!office) {
    return (
      <MaijaContainer style={{ height: "100%" }}>
        <MaijaErrorPage isFullscreen={true} />
      </MaijaContainer>
    );
  }

  return (
    <MaijaContainer>
      <Fab
        variant="extended"
        color="secondary"
        style={{
          position: "fixed",
          bottom: "60px",
          right: "60px",
        }}
        onClick={() => setOfficeReportModalOpen(true)}
      >
        <AddIcon sx={{ mr: 1 }} />
        <Typography variant="body2">{t("officeDetails.generateReport")}</Typography>
      </Fab>

      <OfficeReportModal
        officeId={office.officeId}
        isOpen={officeReportModalOpen}
        handleClose={() => setOfficeReportModalOpen(false)}
      />

      <ConfirmActionDialog
        open={removeOfficeModalOpen}
        title={t("officeDetails.removeOfficeConfirmationTitle", { name: office.name })}
        description={t("officeDetails.removeOfficeConfirmationDescription", { name: office.name })}
        onClose={() => setRemoveOfficeModalOpen(false)}
        onAction={() => handleRemoveOffice(office.officeId)}
      />

      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ mx: 2 }}>
          <Typography variant="h2" gutterBottom sx={{ display: "flex", alignItems: "flex-end" }}>
            {office.name}
          </Typography>
          <Button
            variant="contained"
            color="error"
            sx={{ py: 2, px: 4, mr: 2, my: 2, borderRadius: "50px" }}
            onClick={() => {
              setRemoveOfficeModalOpen(true);
            }}
          >
            {t("officeDetails.removeOffice")}
          </Button>
        </Box>

        <Box mt={3} display="flex" flexDirection="row" justifyContent="space-around">
          <MaijaInfoCard
            icon={<SupervisedUserCircle />}
            title={t("officeDetails.jobCoaches")}
            value={office.numberJobCoaches}
            navigateToAdd={() => {
              dispatch(setCompanyCreateSelectedTab(CompanyCreatePageTab.JobCoachTab));
              dispatch(setCompanyCreateSelectedOffice(officeDetailsToOfficeItem(office)));
              navigate(COMPANY_CREATE_ROUTE.path);
            }}
          />
          <MaijaInfoCard
            icon={<People />}
            title={t("officeDetails.applicants")}
            value={office.numberApplicants}
            navigateToAdd={() => {
              dispatch(setCompanyCreateSelectedTab(CompanyCreatePageTab.ApplicantTab));
              dispatch(setCompanyCreateSelectedOffice(officeDetailsToOfficeItem(office)));
              navigate(COMPANY_CREATE_ROUTE.path);
            }}
          />
          <MaijaInfoCard
            icon={<ArticleIcon />}
            title={t("officeDetails.appliedJobs")}
            value={office.numberAppliedJob}
          />
          <MaijaInfoCard icon={<HowToRegIcon />} title={t("officeDetails.foundJobs")} value={office.numberGotJob} />
        </Box>
      </Box>

      <Box sx={{ mt: 2, mx: 2, mb: 30 }} display="flex" flexDirection="column">
        <Typography variant="h4" gutterBottom sx={{ ml: 2 }}>
          {t("officeDetails.jobCoachesTitle")}
        </Typography>
        <JobCoachList jobCoaches={office.jobCoachItems} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </Box>
    </MaijaContainer>
  );
};

interface OfficeReportModalProps {
  officeId: string;
  isOpen: boolean;
  handleClose: () => void;
}

const OfficeReportModal: React.FC<OfficeReportModalProps> = ({ officeId, isOpen, handleClose }) => {
  const { t } = useTranslation();

  return (
    <MaijaModal isOpen={isOpen} handleClose={handleClose}>
      <MaijaAsyncComponent fetchData={() => getOfficeReport(officeId)} minHeight={500}>
        {(report: StatusReport) => (
          <Box display="flex" flexDirection="column" width="100%">
            <Typography variant="h5">{t("officeDetails.reportTitle", { name: report.name })}</Typography>
            <Typography sx={{ mt: 3 }} variant="h6">
              {t("officeDetails.summary")}
            </Typography>
            <Typography sx={{ mt: 2 }}>{report.summary}</Typography>
            <Typography sx={{ mt: 3 }} variant="h6">
              {t("officeDetails.improvementPlan")}
            </Typography>
            <Typography sx={{ mt: 2, mb: 3 }}>{report.improvementPlan}</Typography>
          </Box>
        )}
      </MaijaAsyncComponent>
    </MaijaModal>
  );
};

export default OfficeDetailsPage;
