import axiosInstance from "../../utils/AxiosInstance";

export async function createOffice(officeName: string): Promise<CreateOfficeResponse | undefined> {
  try {
    const response = await axiosInstance.post("/office/create", {
      office_name: officeName,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

export async function createJobCoach(
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  officeId: string,
): Promise<CreateJobCoachResponse | undefined> {
  try {
    const response = await axiosInstance.post("/job_coach/create", {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      office_id: officeId,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

export type CreateOfficeResponse = {
  id: string;
  name: string;
};

export type CreateJobCoachResponse = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
};
