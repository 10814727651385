import { Box, Typography, Button, Card, CircularProgress, FormControl, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MaijaModal from "../../components/MaijaModal";
import { ApplicantReport } from "../../types/ApplicantReport";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  generateApplicantActionPlan,
  getApplicantReport,
  getNewApplicantReport,
  sendApplicantReportEmail,
} from "./ReportsRepository";
import { MaijaA4Paper } from "../../components/MaijaA4Paper";
import { Download, Email } from "@mui/icons-material";
import { applicantDetailsPageSlice } from "../../reducers/applicantDetailsPageSlice";
import { downloadBase64File } from "../../utils/FileDownloadUtil";
import Flag from "react-world-flags";
import ApplicationLanguage, { getLanguageByCode, SUPPORTED_LANGUAGES } from "../../types/ApplicationLanguage";

enum ModeType {
  GENERATE = "GENERATE",
  LOAD_EXISTING = "LOAD_EXISTING",
}

export class ResumePreviewModalMode {
  static GENERATE = new ResumePreviewModalMode(ModeType.GENERATE);

  static LOAD_EXISTING(reportId: string) {
    return new ResumePreviewModalMode(ModeType.LOAD_EXISTING, reportId);
  }

  mode: ModeType;
  reportId?: string;

  private constructor(mode: ModeType.GENERATE | ModeType.LOAD_EXISTING, reportId?: string) {
    this.mode = mode;
    this.reportId = reportId;
  }
}

interface ResumePreviewModalProps {
  isOpen: boolean;
  applicantId: string;
  reportMode: ResumePreviewModalMode;
  handleClose: () => void;
}

const ReportPreviewModal: React.FC<ResumePreviewModalProps> = ({ applicantId, isOpen, handleClose, reportMode }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [isGeneratingActionPlan, setGeneratingActionPlan] = useState(false);
  const applicant = useAppSelector((state) =>
    applicantId ? state.applicantDetailsPageSlice.applicantsMap[applicantId] : undefined,
  );
  const [language, setLanguage] = useState<ApplicationLanguage>(getLanguageByCode("sv"));
  const [applicantReport, setApplicantReport] = useState<ApplicantReport | undefined>(undefined);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      const fetchApplicantReport = async () => {
        try {
          if (reportMode.mode === ModeType.LOAD_EXISTING) {
            const newReport = await getApplicantReport(applicantId, reportMode.reportId!);
            setApplicantReport(newReport);
          } else if (reportMode.mode === ModeType.GENERATE) {
            const newReport = await getNewApplicantReport(applicantId);
            dispatch(
              applicantDetailsPageSlice.actions.addApplicantDetailsReportsList({ applicantId, reports: newReport }),
            );
            setApplicantReport(newReport);
          } else {
            throw new Error("Invalid report mode");
          }
        } catch (error) {
          dispatch(
            showSnackbar({
              message: t("applicantDetails.report.errorLoading"),
              severity: "error",
            }),
          );
          handleClose();
        } finally {
          setIsLoading(false);
        }
      };

      fetchApplicantReport();
    }
  }, [isOpen, applicantId, dispatch, reportMode, t, handleClose]);

  const handleDownload = () => {
    if (!applicantReport) {
      dispatch(
        showSnackbar({
          message: t("generic.error"),
          severity: "error",
        }),
      );
    } else {
      const fileName = `${applicant?.contactInformation.firstName}_${applicant?.contactInformation.lastName}_report`;
      downloadBase64File(applicantReport.applicantReportPdfEncoded, fileName);
    }
  };

  const handleEmail = async () => {
    if (!applicantReport) return;

    try {
      setIsEmailSending(true);
      await sendApplicantReportEmail(applicantId, applicantReport.id!);
      dispatch(
        showSnackbar({
          message: t("applicantDetails.report.emailSent"),
          severity: "success",
        }),
      );
    } catch (error) {
      dispatch(
        showSnackbar({
          message: t("generic.error"),
          severity: "error",
        }),
      );
    } finally {
      setIsEmailSending(false);
    }
  };

  const handleGenerateActionPlan = async () => {
    if (!applicantReport) return;

    try {
      setGeneratingActionPlan(true);
      const actionPlan = await generateApplicantActionPlan(applicantId, applicantReport.id!, language.code);
      const fileName = `${applicant?.contactInformation.firstName}_${applicant?.contactInformation.lastName}_handlings_plan`;
      downloadBase64File(actionPlan.pdfEncoded, fileName);

      dispatch(
        showSnackbar({
          message: t("applicantDetails.report.actionPlanCreated"),
          severity: "success",
        }),
      );
    } catch (error) {
      dispatch(
        showSnackbar({
          message: t("generic.error"),
          severity: "error",
        }),
      );
    } finally {
      setGeneratingActionPlan(false);
    }
  };

  return (
    <MaijaModal isOpen={isOpen} handleClose={handleClose} width="70%" maxHeight="95vh" overflowY="visible" p={0}>
      <Box sx={{ display: "flex", height: "90vh", maxHeight: "95vh" }}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            alignItems: "center",
            pt: 5,
            pb: 5,
            pl: 5,
          }}
        >
          <>
            {applicantReport?.applicantReportThumbnailsEncoded.map((thumbnail, index) => {
              return (
                <Box key={index} sx={{ mb: 2, width: "100%" }}>
                  <MaijaA4Paper loading={isLoading}>
                    {!isLoading && (
                      <img
                        src={`data:image/jpeg;base64,${thumbnail}`}
                        alt={`Cover Letter Page ${index + 1}`}
                        style={{ width: "100%", height: "auto", borderRadius: "8px" }}
                      />
                    )}
                  </MaijaA4Paper>
                </Box>
              );
            })}
            {isLoading && <MaijaA4Paper loading={true} />}
          </>
        </Box>
        <Box
          sx={{
            width: "300px",
            p: 5,
            overflowY: "auto",
          }}
        >
          <Card sx={{ p: 5 }}>
            <Typography variant="body1" sx={{ mb: 3, fontWeight: "bold", fontSize: "1.1rem" }}>
              {t("applicantDetails.report.manage")}
            </Typography>

            <Button
              variant="text"
              color="inherit"
              startIcon={<Download />}
              onClick={handleDownload}
              fullWidth
              disabled={!applicantReport}
              sx={{ justifyContent: "flex-start", pl: 2, mb: 2 }}
            >
              {t("applicantDetails.report.download")}
            </Button>

            <Button
              variant="text"
              color="inherit"
              startIcon={isEmailSending ? <CircularProgress size={20} /> : <Email />}
              onClick={handleEmail}
              fullWidth
              disabled={!applicantReport || isEmailSending}
              sx={{ justifyContent: "flex-start", pl: 2, mb: 2 }}
            >
              {isEmailSending ? t("applicantDetails.report.sending") : t("applicantDetails.report.email")}
            </Button>

            {/* <Button
              variant="text"
              color="inherit"
              startIcon={isGeneratingActionPlan ? <CircularProgress size={20} /> : <Create />}
              onClick={handleGenerateActionPlan}
              fullWidth
              disabled={!applicantReport || isGeneratingActionPlan}
              sx={{ justifyContent: "flex-start", pl: 2 }}
            >
              {isGeneratingActionPlan ? t("applicantDetails.report.generating") : t("applicantDetails.report.actionPlan")}
            </Button> */}
          </Card>

          <Card sx={{ p: 5, mt: 5 }}>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold", fontSize: "1.1rem" }}>
              {t("Handlingsplan")}
            </Typography>

            <Typography variant="body1" sx={{ mb: 1 }}>
              {t("Välj språk")}
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Select
                labelId="language-label"
                value={language.code}
                onChange={(e) => setLanguage(getLanguageByCode(e.target.value))}
                renderValue={(selected) => {
                  const selectedLanguage = SUPPORTED_LANGUAGES.find((lang) => lang.code === selected);
                  return selectedLanguage ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Flag code={selectedLanguage.flag} style={{ marginRight: 8 }} width={20} />
                      {selectedLanguage.name}
                    </Box>
                  ) : (
                    <em>{t("applicationDetails.page.changeApplicationOriginalLanguage")}</em>
                  );
                }}
              >
                {SUPPORTED_LANGUAGES.map((language) => (
                  <MenuItem key={language.code} value={language.code}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Flag code={language.flag} style={{ marginRight: 8 }} width={20} />
                      {language.name}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              startIcon={isGeneratingActionPlan ? <CircularProgress size={20} /> : <></>}
              onClick={handleGenerateActionPlan}
              fullWidth
              disabled={!applicantReport || isGeneratingActionPlan}
              sx={{ pl: 2 }}
            >
              {isGeneratingActionPlan ? t("applicantDetails.report.generating") : t("Skapa")}
            </Button>
          </Card>
        </Box>
      </Box>
    </MaijaModal>
  );
};

export default ReportPreviewModal;
