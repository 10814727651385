import React, { useState, createContext, ReactNode, useEffect } from "react";
import { User } from "../types/User";
import { AuthContextType } from "../types/AuthContextType";
import { useAppDispatch } from "../hooks";
import { clearLoggedInUser, setLoggedInUser } from "../reducers/userSlice";

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated: false,
  loading: true,
  setUser: (_user: User | null) => {},
  setIsAuthenticated: (_isAuthenticated: boolean) => {},
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const storedUser = sessionStorage.getItem("currentUser");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      setIsAuthenticated(true);
      dispatch(setLoggedInUser(parsedUser));
    }
    setLoading(false);
  }, [dispatch]);

  const authContextValue = {
    user,
    isAuthenticated,
    loading,
    setUser: (newUser: User | null) => {
      setUser(newUser);
      if (newUser) {
        sessionStorage.setItem("currentUser", JSON.stringify(newUser));
        setIsAuthenticated(true);
        dispatch(setLoggedInUser(newUser));
      } else {
        sessionStorage.removeItem("currentUser");
        setIsAuthenticated(false);
        dispatch(clearLoggedInUser());
      }
    },
    setIsAuthenticated,
  };

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};
