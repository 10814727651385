import store from "../store";
import axiosInstance from "./AxiosInstance";
import { clearLoggedInUser } from "../reducers/userSlice";
import { clearPlanningPage } from "../reducers/planningPageSlice";
import { clearApplicantDetailsPage } from "../reducers/applicantDetailsPageSlice";
import { clearApplicantListPage } from "../reducers/applicantListPageSlice";
import { clearJobCoachListPage } from "../reducers/jobCoachListPageSlice";
import { clearJobCoachDetailsPageSlice } from "../reducers/jobCoachDetailsPageSlice";
import { clearCompanyCreatePage } from "../reducers/companyCreatePageSlice";
import { clearTheme } from "../reducers/themeSlice";
import { clearAnalyticsPage } from "../reducers/analyticsPageSlice";

export async function performLogout() {
  const dispatch = store.dispatch;

  try {
    await axiosInstance.post("/logout");
  } catch (error) {
    console.error("Logout request failed:", error);
  } finally {
    dispatch(clearLoggedInUser());
    dispatch(clearAnalyticsPage());
    dispatch(clearApplicantDetailsPage());
    dispatch(clearApplicantListPage());
    dispatch(clearPlanningPage());
    dispatch(clearJobCoachListPage());
    dispatch(clearJobCoachDetailsPageSlice());
    dispatch(clearCompanyCreatePage());
    dispatch(clearTheme());
  }
}
