import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { OfficeDetails } from "../types/OfficeDetails";

interface OfficeDetailsPageState {
  officesMap: { [id: string]: OfficeDetails };
}

const initialState: OfficeDetailsPageState = {
  officesMap: {},
};

export const officeDetailsPageSlice = createSlice({
  name: "officeDetails",
  initialState,
  reducers: {
    setOffice: (state, action: PayloadAction<OfficeDetails>) => {
      state.officesMap[action.payload.officeId] = action.payload;
    },
    clearOfficeDetailsPageSlice: (state) => {
      state.officesMap = {};
    },
  },
});

export const { setOffice, clearOfficeDetailsPageSlice } = officeDetailsPageSlice.actions;
export const officeDetailsPage = (state: RootState) => state.applicantDetailsPageSlice;
export default officeDetailsPageSlice.reducer;
